.breadcrumb>li+li:before {
    content: "\3E" !important;
}

.jk a {
    color: black !important;
    font-size: 16px;

}

.document-Image-Div {
    /* border: 1px solid red; */
    background: url(/public/images/Group884.png)no-repeat center center/cover;
    width: 122px;
    height: 76px;
    cursor: pointer;
}

.selDoc-Selef-Img-Div-One{
    background: url(/public/images/Group999.png)no-repeat center center/cover;
    width: 100%;
    height: 40px;
    /* border:1px solid red; */
}

.selDoc-Selef-Img-Div-Two{
    background: url(/public/images/Group999.png)no-repeat center center/cover;
    width: 100%;
    height: 40px;
}

.sidhiLineImage {
    width: 25%;
    margin-top: 50px;
}
.document-Image-Sell-Main-Div{
    position: relative;
    /* top:-5px; */
}
.docText-Tag-Div {
    position: absolute;
    top: 28px;
    cursor: pointer;
    /* border: 2px solid red; */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-left: 15px;
    padding-right: 10px;
}

.docText-Tag-H6 {
    position: relative;
    font-size: 9px;
    color: white;
    /* margin-left: 10px; */
    align-self: center !important;
    
}

.docText2-Tag-Div-text-Two{
    /* border:1px solid black; */
    top:5px;
    position:absolute;   
}
.selDoc-Img-Main-Div {
    /* border: 1px solid black; */
    width: 100%;
    position: relative;
    cursor: pointer;
}

.docText2-Tag-Div {
    position: absolute;
    top: 5px;
    
}



#tree {
    display: inline-block;
    padding: 10px;
    margin-top: -36px;
}

#tree * {
    box-sizing: border-box;
}

#tree .branch {
    padding: 5px 0 5px 20px;
}


#tree .branch:not(:first-child) {
    margin-left: -7px;
    /* border: 1px solid red; */
    margin-top: 0px;
    padding-top: 0px;

}

/* #tree .branch:not(:first-child):after {
  content: "";
  width: 22px;
  border-top: 2px solid rgb(163, 160, 160);
  position: absolute;
  left: -30px;
  top: 15%;
  margin-top: 10px; 
} */
#tree .BranchOne:not(:first-child):after {
    content: "";
    width: 22px;
    border-top: 2px solid rgb(163, 160, 160);
    position: absolute;
    left: -30px;
    top: 34%;
    margin-top: 10px;

}

.entry {
    position: relative;
    min-height: 78px;
    display: block;
}

.entry:before {
    content: "";
    height: 100%;
    border-left: 2px solid rgb(163, 160, 160);
    position: absolute;
    left: -20px;
}

.entry:first-child:after {
    height: 10px;
    border-radius: 10px 0 0 0;
}

.entry:first-child:before {
    width: 10px;
    height: 50%;
    top: 50%;
    margin-top: 1px;
    border-radius: 10px 0 0 0;
}

.entry:after {
    content: "";
    width: 20px;
    border-top: 2px solid rgb(163, 160, 160);
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: 1px;
}

.entry:last-child:before {
    width: 10px;
    height: 50%;
    border-radius: 0 0 0 10px;
}

.entry:last-child:after {
    height: 10px;
    border-top: none;
    transition: border 0.5s;
    border-radius: 0 0 0 10px;
    margin-top: -9px;
}

.entry:only-child:after {
    width: 10px;
    height: 0px;
    margin-top: 1px;
    border-radius: 0px;
}

.entry:only-child:before {
    display: none;
}

.entry span {
    width: 150px;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    font-family: arial, verdana, tahoma;
    font-size: 14px;
    transition: all 0.5s;
}

/* @media (max-width:995px) {
   
    .document-Image-Div img {
        width: 188px;
    }

    .sidhiLineImage {
       height: 10px !important;
    }

    .docText-Tag-Div {
        position: absolute;
        top: 52px;
    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }

    .selDoc-Img-Main-Div {
        width: 100%;
        position: relative;
        cursor: pointer;
        display: flex;
        top: -7px;
        justify-content: center;
        align-items: center;
    }

    

} */
@media (max-width:992px) and ((max-width:1000px)) {
    .document-Image-Div {
        /* border: 1px solid black ; */
        width: 188px;
        height: 96px;
        
    }
    .selDoc-Selef-Img-Div-One{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
        margin-top: 10px;
    }
    .selDoc-Selef-Img-Div-Two{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
    }
    
    .sidhiLineImage {
        width: 18%;
        height: 20% !important;
        margin-top: 50px;
    }
    .document-Image-Sell-Main-Div{
        position: relative;
        /* top:-9px; */
    }
    .docText-Tag-Div {
        position: absolute;
        top: 42px;
    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }
    .docText2-Tag-Div-text-Two{
        position: absolute;
        top: 20px;
    }

    .selDoc-Img-Main-Div {
        /* border: 1px solid black; */
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }
    .selDoc-Img-Main-Div-Two{
        top:-1px;
    }
}

@media (max-width:850px) {
    .document-Image-Div {
        /* border: 1px solid black ; */
        width: 188px;
        height: 96px;
        
    }
    .selDoc-Selef-Img-Div-One{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
        margin-top: 10px;
    }
    .selDoc-Selef-Img-Div-Two{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
    }
    .selDoc-Img-Main-Div-One{
        /* border: 1px solid red !important; */
        top:-7px;
    }
    .sidhiLineImage {
        width: 18%;
        height: 20% !important;
        margin-top: 50px;
    }
    .document-Image-Sell-Main-Div{
        position: relative;
        /* top:-9px; */
    }
    .docText-Tag-Div {
        position: absolute;
        top: 42px;
    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }
    .docText2-Tag-Div-text-Two{
        position: absolute;
        top: 20px;
    }

    .selDoc-Img-Main-Div {
        /* border: 1px solid black; */
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }
    .selDoc-Img-Main-Div-Two{
        top:-1px;
    }
}

@media (min-width:500px) and (max-width:575px) {
    .document-Image-Div {
        border: 1px solid black ;
        /* width: 188px !important;
        height: 96px; */
        
    }
    .selDoc-Selef-Img-Div-One{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
        margin-top: 10px;
    }
    .selDoc-Selef-Img-Div-Two{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
    }
    .selDoc-Img-Main-Div-One{
        /* border: 1px solid red !important; */
        top:-7px;
    }
    .sidhiLineImage {
        width: 18%;
        height: 20% !important;
        margin-top: 50px;
    }
    .document-Image-Sell-Main-Div{
        position: relative;
        /* top:-9px; */
    }
    .docText-Tag-Div {
        position: absolute;
        top: 42px;
    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }
    .docText2-Tag-Div-text-Two{
        position: absolute;
        top: 20px;
    }

    .selDoc-Img-Main-Div {
        /* border: 1px solid black; */
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }
    .selDoc-Img-Main-Div-Two{
        top:-1px;
    }
}

@media (max-width:430px) {
    .document-Image-Div {
        /* border: 1px solid black ; */
        width: 150px;
        height: 86px;
        
    }
    .selDoc-Selef-Img-Div-One{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        /* border: 1px solid red; */
    }
    .selDoc-Selef-Img-Div-Two{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
    }
    .selDoc-Img-Main-Div-One{
        /* border: 1px solid red !important; */
        top:-4px;
    }
    .sidhiLineImage {
        width: 18%;
        height: 20% !important;
        margin-top: 50px;
    }
    .document-Image-Sell-Main-Div{
        position: relative;
        /* top:-9px; */
        /* border: 1px solid red; */

    }
    .docText-Tag-Div {
        position: absolute;
        top: 36px;
        /* border: 1px solid red; */

    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }
    .docText2-Tag-Div-text-Two{
        position: absolute;
        top: 20px;
        /* border: 1px solid red; */

    }

    .selDoc-Img-Main-Div {
        /* border: 1px solid black; */
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }
    .selDoc-Img-Main-Div-Two{
        top:-5px;
        /* border: 1px solid red; */
    }
    .docText2-Tag-Div{
        /* border: 1px solid red; */
        top:10px;
    }
}
@media (max-width:330px) {
    .document-Image-Div {
        /* border: 1px solid black ; */
        width: 113px;
        height: 76px;
        
    }
    .selDoc-Selef-Img-Div-One{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        /* border: 1px solid red; */
    }
    .selDoc-Selef-Img-Div-Two{
        background: url(/public/images/Group999.png)no-repeat center center/cover;
        width: 100%;
        height: 50px;
    }
    .selDoc-Img-Main-Div-One{
        /* border: 1px solid red !important; */
        top:-4px;
    }
    .sidhiLineImage {
        width: 18%;
        height: 20% !important;
        margin-top: 50px;
    }
    .document-Image-Sell-Main-Div{
        position: relative;
        /* top:-9px; */
        /* border: 1px solid red; */

    }
    .docText-Tag-Div {
        position: absolute;
        top: 36px;
        /* border: 1px solid red; */

    }

    .docText-Tag-H6 {
        font-size: 12px;
        color: white;
    }
    .docText2-Tag-Div-text-Two{
        position: absolute;
        top: 20px;
        /* border: 1px solid red; */

    }

    .selDoc-Img-Main-Div {
        /* border: 1px solid black; */
        width: 100%;
        position: relative;
        cursor: pointer;
        
    }
    .selDoc-Img-Main-Div-Two{
        top:-5px;
        /* border: 1px solid red; */
    }
    .docText2-Tag-Div{
        /* border: 1px solid red; */
        top:10px;
    }
}
