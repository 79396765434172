.jk a{
    display: flex;
    justify-content: start;
    align-self: center;
}

.accordion-button:checked{
border: white !important;
box-shadow: white;
}
@media (max-width:600px) {
    .img-Div{
        width: 40px !important;
        
    }
    .text-Div{
        height: 40px !important;
    }
}