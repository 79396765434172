.Question-Card-Div {
  border: 2px dashed rgb(214, 211, 211);
  margin-top: 10px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 30px;
  padding-top: 15px;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-right: 10px;
}
.Question-Div {
  border: 2px dashed rgb(234, 228, 228);
}
Radio {
  font-weight: 500;
}
.Sava-Button-Div {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 20px;
}
.Save-Button {
  background-color: #720c20;
  border-radius: 30px;
  border: none;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 500;
}
.textEditor-Col {
  box-shadow: 1px 2px 4px rgb(172, 170, 170) !important;
  flex-wrap: wrap;
  padding: 20px;
}
.Agent-Preview-Col {
  box-shadow: 1px 2px 4px rgb(172, 170, 170) !important;
  flex-wrap: wrap;
  padding: 20px;
}
.Uplaod-Button {
  background-color: black;
  opacity: 85%;
  border-radius: 30px;
  border: none;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 13px;
  padding-bottom: 38px;
  font-weight: 500;
}
.Uplaod-Button:hover {
  background-color: black;
  opacity: 85%;
  border-radius: 30px;
  border: none;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 13px;
  padding-bottom: 38px;
  font-weight: 500;
}
.addnewproffbtn{
  border: none;
  /* width: 30px; */
  height: auto;
 background-color: transparent;
 cursor: pointer;
 font-size: 25px;
 font-weight: bold;
 text-align: center;
 margin-top: 2px;
 /* margin: 5px 5px; */
}
/* .heading {
  font-size: 16px;
}

.list {
  font-size: 13px;
} */

.blocks {
  border: 1px solid gray;
  padding: 20px;
  margin-top: 10px;
}
.modalsetguidance{
  color: #ffffff;

 }
 .btnclose{
  color: #ffffff !important; 
  opacity: 1;
  background-color: transparent;
border: none;
}
.modal-img{
  width: 70px;
  height: auto;
}
.modal-div{
  width: 50%;
  height: 150px;
padding: 25px;
margin: 0px 10px;
text-align: center;
border: 2px solid lightgray;
border-radius: 10px;
cursor: pointer;
box-shadow: 1px 1px 1px 1px rgba(211, 211, 211, 0.858);
margin-bottom: 20px;
}
.center-item{
  margin: 0px auto;
  font-weight: 600;
}
.modal-header-color{
background-color: #720c20;
color: #ffffff !important;
}
.Question-Card-Col {
  border: 2px dashed rgb(214, 211, 211);
  border-radius: 10px;
 
  padding-top: 15px;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-right: 10px;
}
.select-Div{
  width: 25%;
}

@media (max-width:600px) {
  .select-Div{
    width: 100% !important;
  }
}
@media (max-width: 800px) {
  /* input{
        width: 400px !important;
        
    } */
}
