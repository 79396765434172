.sider {
  /* border:1px solid red; */
  /* overflow: scroll; */
  /* height: 700px; */
  /* border: 1px solid red; */ 
   /* position: sticky;
   top: 100px;  */
}
.DashBoardCard{
  /* border-radius: 10px; */
  height: 60px;
  box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.2);
}
.DashBoardCardsquer{
  /* border-radius: 10px; */
  height: 60px;
  box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.2);
}
.sidebarsquer {
  background-color: white;
  cursor: pointer;
  color: black;
  padding: 0;
  /* box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  height: 64px;
}
.sidebar {
  background-color: white;
  cursor: pointer;
  color: black;
  padding: 0;
  /* box-shadow: 0 1px 3px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 30px;
  height: 64px;
}

/* style={{ height: 84, boxShadow: "0 3px 3px rgba(0,0,0,0.2)" }} */
.changeFont {
  color: black;
  font-size: 18px;
  font-weight: 600;
  
}

.buttonColor {
  color: #720c20;
  font-weight: 600;
  font-size: 18px;
}

.borderOnClick {
  border: 3px solid #720c20;
}

.sidebar:hover:not(.borderOnClick) {
  border: 1.5px solid #AA6D79;
}
.sidebarsquer:hover:not(.borderOnClick) {
  border: 1.5px solid #AA6D79;
}

@media (max-width:500px) {
 .sider{
   width: 280px;
    height: auto;
    /* border: 1px solid red; */
 }
 .sidersq{
  width: 100px;
   height: auto;
   /* border: 1px solid red; */
   margin-right: 10px !important;


}
}
@media (max-width:800px) {
  .sider{
    width: 280px;
     height: auto;
  }
 }







  /* .sidebar{ 
    background-color: white;
    cursor: pointer;
    color: black;  
    padding: 0;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
    border-radius: 6px;
  } 
  .card
  {
    border-radius: 15px;
  }
  .changeFont{
    color: black;
    font-size: 18px;
    font-weight: 500;  
  }
  
  .buttonColor{
    color: #720c20;
    font-weight: 500;
    font-size: 17px;
  }
  .borderOnClick{
    border: 2px solid #720c20;
  }
  
  .sidebar:hover:not(.borderOnClick){
    border: 1.5px solid #AA6D79;
  }


@media (max-width: 320px) {
  .list-group-flush{
    width: 300px;
  }
} */



 