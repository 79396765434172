.Info1 {
  border: 1.5px dashed grey;
  border-radius: 5px;
  padding: 8px;
  /* padding-left: 10px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 8px; */
  /* width: 98.5%; */
  margin-bottom: 10px;
  /* margin-: 10px !important; */
  /* margin-right: 10px;
  margin-left: 8px; */
}
.memberContactCard {
  height: 35px;
  /* padding-bottom: 5px; */
}
.addNewContact {
  border-color: brown;
  color: brown;
}

.maritalInfo {
  width: 100%;
}
.radiogroup {
  color: brown;
}

.MemberContactEdit img {
  width: 20px;
  height: auto;
  float: right;
  margin-top: 5px;
}
.my-modal-class {
  width: 100%;
}
.AddpopupMember {
  margin-left: 10px;
}

.my-modal-class .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  color: #720c20;
}

.ant-modal-footer .btn {
  color: #720c20;
}
.ant-btn-primary {
  background: #720c20 !important;
  border-color: #720c20 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #720c20;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #720c20;
}

.card-div-main {
  max-width: 32%;
  min-width: 32%;
}
