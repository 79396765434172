.activebtn{
    border: 1px solid #720c20;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0px 5px 0px;
    color: #720c20;
    font-weight: 500;
}
.disable{
    border: 1px solid grey;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor:not-allowed;
    margin: 5px 0px  5px 0px;
    color: grey;
    font-weight: 500;
}
.statusbtn{
    line-height: 40px;
    font-weight:600; 
}