.SpouceInfoTag-H5 {
  color: #720c20;
}
.EditSpan {
  color: #720c20;
}
.SpouceNameTag-H4 {
  color: 000000;
  opacity: 70%;
}
.SpouceTag-H6 {
  color: 000000;
  opacity: 50%;
  font-weight: 500;
}

.checkBox {
  font-size: 16px;
  color: 000000;
  opacity: 70%;
}
.cardd {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  margin-right: 9px;
}
.cardd1 {
  /* border: 1px solid black; */
  padding: 20px 6px 15px 20px;
}
.cardd2 {
  padding: 5px 12px 15px 20px;
}
.carddChild {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  width: 230px;
  padding-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.carddChild2 {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  width: 245px;
  padding-top: 70px;
  margin-right: 20px;
  height: 250px;
}
.grandchild{
    border: 2px solid #d9d9d9;
    border-radius: 10px;
    width: 240px;
    padding-top: 25px;
    /* margin-right: 20px; */
    /* margin-bottom: 10px; */
    height: 226px;
}
.RowProfile {
  /* margin-left: 10px; */
}
.scrolling-wrapper {
  /* overflow: scroll;
    overflow-y: hidden; */
}
.chidrenMainCard {
  width: 250px;
  height: auto;
  align-items: center;
  max-height: 250px;
  min-height: 250px;

  /* width: "260px", alignItems: "center", maxHeight: "300", minHeight: "300" */
}
.grandchidrenMainCard {
    width: 240px;
    height: auto;
    align-items: center;
    max-height: 235px;
    min-height: 235px;
  
    /* width: "260px", alignItems: "center", maxHeight: "300", minHeight: "300" */
  }

.NameDiv {
  align-items: center;
  text-align: center;
  overflow: hidden;
  max-height: 40px;
  width: 240px;
  flex-wrap: wrap;
  /* style={{ alignItems: "center", textAlign: "center",overflow:"hidden" ,height: "60px",width:"240px" }} */
}
.childRelationShipDiv {
  align-items: center;
  text-align: center;
}
.tree-div::before {
  content: "....";
  font-size: 30px;
  margin: auto 2px;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .cardd1 {
    padding-left: 10px;
  }
  .cardd2 {
    padding-left: 0px;
    padding-right: 8px;
  }
  .SpouceNameTag-H4 {
    margin-top: 10px;
    font-size: 15px;
  }
}

.vertical-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.vertical-scroll::-webkit-scrollbar-thumb {
  background: rgb(227, 232, 227);
  border-radius: 100vw;
}
.vertical-scroll::-webkit-scrollbar-track {
  background: rgb(89, 91, 89);
  border-radius: 100vw;
  margin-block: 10px;
}

.absolute-bottom{
  position: absolute;
  bottom: 32px;
}
/* @supports (scrollbar-color:red blue){
    scrollbar-color: ;
} */
