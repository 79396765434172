/*IndexFile*/
.logotext{
color:  #871A27;
}
.lpobutton{
    border: 3px solid #E9E9E9;
    border-radius: 10px;
    width: 190px;
    height: 75px;
    font-family: sans-serif;
    font-size: 12px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
    /* padding: 10px; */
}
.logoimg{
    width: 70px;
}
.buttonimg{
    width: 30px;
    margin-left: 50px;
}
.lpobutton:hover{
    background-color: #E9E9E9;
}
.buttonboundary{
    border:2px solid black;
}
.cardboundary{
    cursor: pointer;
width: 200px;
height: 170px;
margin:8px;
background: #FFFFFF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 6px;
}

/*progressbar*/
.progresspoint{
    margin-left:80%;
    
}


/*MemberInfo*/
.MemberInfo-btn{
    margin-right: calc(0 * var(--bs-gutter-x)) !important  ;
  }