@import '~antd/dist/antd.css';

.overLayLoader{
    width: 100vw;
    height: auto;
    min-height: 100vh;
    height:100%;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 10000;
  }
  
.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dashed-border{
    border: 1px dashed #d9d9d9;
}

.disabledInput:disabled{
  background-color: white !important;
}

.cursor-pointer{
  cursor: pointer;
}
.Input {
  color:black !important;
}

.overLayLoader{
  /* width: 100vw; */
  width: 99.99%;
  height: auto;
  /* min-height: 100vh; */
  height:100%;
  background-color: rgb(0, 0, 0, 0.2);
  z-index: 99999;
}
/* .overlay{ height: 100vh;
max-height: 100vh;} */

/* @media (min-width: 992px) {
  .menu {
    display: none;
  }
  
} */

/* @media  (max-width: 710px) {
  
  .Pbtn{
      margin-left:300px;
  }
}


@media  (max-width: 320px) {
 
  
  .Pbtn{
      margin-right: 300px;
  }
} */
.positionFeedBackButton {
  position: fixed;
  top: 45%;
  right: 0;
  transform: rotate(270deg) translate(-50%, 50%) scaleX(1.1);
  z-index: 99;
}
.ant-message{
  z-index:100000;
}