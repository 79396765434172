.top-btnM  {
    border: 3px solid #720c20;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    font-family: sans-serif;
    font-size: 12px;
    background-color: white;
    transition: 0.5s;
    color: #720c20;
}
.RolePopModal{
    border-radius:10px;
}
.delete-btn{
    background-color: #720c20;
    height: 40px;
    width: 100px;
}
.rolePopBtns{
    justify-content: center;
    align-items:center;
}

@media screen and(max-width:500px) {
    .chechBoxes{
        flex-direction: column;
       
    }
}
/* .chechBoxes{
    display: flex;
} */

