.top-btn1{ border: 1px solid brown; padding-top: 10px; padding-left: 40px; padding-right: 40px; padding-bottom: 10px;}

.manage-search-btn{ width:80%; border-right: none;}
.manage-search img {width: 15px; height: 33px;}
.manage-search { border-left: none;}

.delete-cencel{ text-decoration: underline; color: brown; text-align: center;}
.delete-btn{margin-left: 43%; background-color: brown; color: white;}

.add-btn{ background-color: brown; padding-bottom: 4px; padding-top: 4px; padding-right: 12px;}

th.ant-table-cell.manage{ color: brown; font-size: medium; text-decoration: solid;  text-align: center; background-color: white;}

