.main {
    width: 100%;
    /* height: 100vh; */
}

.HeaderImageDivs1 {
    height: 24vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ImageDivs11 {
    background: url(/public/images/bg.png) no-repeat center center/cover;
    width: 88%;
    height: 24vh;
    border-radius: 5px;
}

.ImageDivs11 img {
    width: 100%;
    height: 24vh;
    border-radius: 5px;
}

.SetupMainDivs111 {
    width: 10%;
    height: 24vh;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
}

.SetupMainDivs111 .SettingCard {
    height: 24vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.myProfileColMainDivs3 {
    height: 17vh;
}

.myProfileAllDiv {
    height: 12vh;
    margin-top: 7px;
    /* border: 1px solid red; */
}

.myProfileAllDiv .s33 {
    width: 24%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
}

.AgentGuidanceMainDivs2 {
    height: 24vh;
    
    /* border: 1px solid red; */
}

.agentGuidanceDiv {
    height: 18vh;
    min-height: 18vh;
    max-height: 18vh;
    margin-top: 7px;
    /* border: 1px solid red; */
}

.s22 {
    width: 32%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%); 
}
.s221{
    background: url(/public/images/newlpomyAgent.png) no-repeat center center/cover;
}
.s222{
    background: url(/public/images/newlpoguidance.png) no-repeat center center/cover;
}
.s223{
    background: url(/public/images/newlpocabinet.png) no-repeat center center/cover;
}

.MyProfessionalTeamMainDivs4 {
    height: 20vh;
}

.MyProfessionalTeamDiv {
    height: 15vh;
    min-height: 15vh;
    max-height: 15vh;
    /* border: 1px solid red; */
}

.s44 {

    width: 19%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
}

.updateLpo {
    font-size: 10px;
    text-decoration: underline;
    color: brown;
}

.SettingImage img {
    height: 40px !important;
}

.NewLpouserTextDivProTeam p {
    color: #751521;
    font-weight: 500;
}

 @media (min-width:1700px) {
    .SettingImage img {
        height: 70px !important;
    }

    .SetupDiv h6 {
        margin-top: 10px;
        font-size: 24px !important;
    }

    .myProfileCol h6 {
        font-size: 20px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .NewLpouserDiv img {
        height: 60px !important;
        margin-left: 25px;
    }

    .NewLpouserTextDiv p {
        font-size: 20px;
        margin-left: 10px;
    }

    .updateLpo {
        font-size: 15px;
    }
    
    .AgentsGuidanceOption h6{
        font-size: 24px !important;
    }
    .AgentGuidanceColHeading h6 {
        font-size: 20px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .MyProfessionalTeamheading h6 {
        font-size: 20px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .NewLpouserTextDivProTeam p {
        color: #751521;
        font-weight: 500;
        font-size: 20px;
    }

    .NewLpouserTextDivProTeam {
        margin-left: 32px;
    }
    .my-Profile-Div img{
        height: 75px !important;
    }
    .my-Profile-Div-img img{
        height: 70px !important;
    }
} 

/* @media (min-width:1600px) {
    .SettingImage img {
        height: 80px !important;
    }

    .SetupDiv h6 {
        font-size: 30px !important;
    }

    .myProfileCol h6 {
        font-size: 30px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .NewLpouserDiv img {
        height: 100px;
        margin-left: 25px;
    }

    .NewLpouserTextDiv p {
        font-size: 30px;
        margin-left: 14px;
    }

    .updateLpo {
        font-size: 18px;
    }
    
    .AgentsGuidanceOption h6{
        font-size: 30px !important;
    }
    .AgentGuidanceColHeading h6 {
        font-size: 30px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .MyProfessionalTeamheading h6 {
        font-size: 30px !important;
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px !important;
    }

    .NewLpouserTextDivProTeam p {
        color: #751521;
        font-weight: 500;
        font-size: 30px;
    }

    .NewLpouserTextDivProTeam {
        margin-left: 42px;
    }
    .my-Profile-Div img{
        height: 75px !important;
    }
    .my-Profile-Div-img img{
        height: 70px !important;
    }
} */


@media(max-width:768px) {

    .ImageDivs11 {
        /* background: url(/public/images/bg.png) no-repeat center center/cover; */
        width: 84%;
        height: 24vh;
        border-radius: 5px;
    }
    
    .ImageDivs11 img {
        width: 100%;
        height: 24vh;
        border-radius: 5px;
    }
    
    .SetupMainDivs111 {
        width: 14%;
        height: 24vh;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
    }
    .NewLpouserDiv img {
        height: 34px;
    }
    .NewLpouserTextDiv p{
        font-size: 15px;
    }

    .NewLpouserTextDivProTeam p {
        color: #751521;
        font-weight: 500;

        font-size: 13px;
    }

}
@media(max-width:601px) and (max-width:767px) {
    .NewLpouserDiv img {
        height: 28px;
    }

    .NewLpouserTextDivProTeam p {
        color: #751521;
        font-weight: 500;
        font-size: 10px;
    }

}
@media (max-width:600px) {
    .ImageDivs11 {
        width: 100%;

    }

    .HeaderImageDivs1 {
        display: block;
        padding: 10px;
        height: auto;
    }

    .SetupMainDivs111 {
        width: 100%;
        margin-top: 10px;
    }

    .myProfileColMainDivs3 {
        display: block;

        flex-wrap: wrap;
        /* border: 5px solid black; */
        height: 62vh;
    }

    .myProfileAllDiv .s33 {
        width: 100%;
        display: block;
        margin-top: 10px;
    }

    .AgentGuidanceMainDivs2 {
        display: block;
        flex-wrap: wrap;
        /* border: 5px solid black; */
        height: 66.5vh;
    }

    .agentGuidanceDiv .s22 {
        width: 100%;
        display: block;
        margin-top: 10px;
    }

    .MyProfessionalTeamMainDivs4 {
        display: block;
        flex-wrap: wrap;
        /* border: 5px solid black; */
        height: 87vh;
    }

    .MyProfessionalTeamDiv .s44 {
        width: 100%;
        display: block;
        margin-top: 10px;
    }
    .NewLpouserTextDivProTeam p{
        font-size: 13px;
    }
}


.upperlayercss{
    position: absolute;
    z-index: 10;
    width:99%;
    height: 63vh;
    margin-top: 5px;
    
    background-color: rgb(0, 0, 0, 0.2);

}