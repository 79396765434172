/* 

.manage-search-btn {
    width: 40%;
    border-right: none;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

}

.manage-search img {
    width: 15px;
    height: auto;
}

.manage-search {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.schedule {
    border: 3px solid #E9E9E9;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    font-family: sans-serif;
    font-size: 12px;
    background-color: white;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}

.schedule:hover {
    border: 2px solid rgba(114, 12, 32, 1);
}

.schedule:focus {
    border: 2px solid rgba(114, 12, 32, 1);
    color: rgba(114, 12, 32, 1);
}

.ant-btn-primary {
    color: #fff;
    border-color: #18ff25;
    background: #18ff4a;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}


.managesearch {
    border-left: none;
    text-align: center;
    border-top-left-radius: none;
    position: absolute;
    margin-right: 10px;
}

.delete-cencel {
    text-decoration: underline;
    color: #a52a2a;
    text-align: center;
}

.delete-btn {
    margin-left: 43%;
    background-color: #720c20;
    color: white;
}

.SucessfullyLoding {
    margin-left: 35%;
}

.Fiduciaries-add img {
   
}

.Fiduciaries-add {
    background-color: #720c20;
}

th.ant-table-cell.manage {
    color: #720c20;
    font-size: medium;
    text-decoration: solid;
    text-align: center;
}

.ant-input-search-button {
    background: #ccc;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgba(0, 0, 0, 0.45);
    background: lightgray;
}

.ant-table-tbody .table-body {
    padding: 0 !important;
}

.ant-table-tbody>tr>td{
    padding: 0 10px !important;
}


.Delete-fiduciaries {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-right: 5px;
} */


.contact-Info-Row{
    /* border: 5px solid red !important; */
    margin-top: 0 !important;
}


.manage-search-btn {
    width: 40%;
    border-right: none;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

}

.manage-search img {
    width: 15px;
    height: auto;
}

.manage-search {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.schedule {
    border: 3px solid #E9E9E9;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    font-family: sans-serif;
    font-size: 12px;
    background-color: white;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
    /* transition: 0.5s; */
}

.schedule:hover {
    border: 2px solid rgba(114, 12, 32, 1);
}


.schedule:active {
    border: 2px solid rgba(114, 12, 32, 1);
}

.schedule:focus {
    border: 2px solid rgba(114, 12, 32, 1);
    color: rgba(114, 12, 32, 1);
}

button.schedule.bg-white.fw-bold.ms-2.familyInfoButton {
    border: 2px solid rgba(114, 12, 32, 1);
}

.ant-btn-primary {
    color: #fff;
    border-color: #18ff25;
    background: #18ff4a;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}


.managesearch {
    border-left: none;
    text-align: center;
    border-top-left-radius: none;
    position: absolute;
    margin-right: 10px;
}

.delete-cencel {
    text-decoration: underline;
    color: #a52a2a;
    text-align: center;
}

.delete-btn {
    margin-left: 43%;
    background-color: #720c20;
    color: white;
}

.SucessfullyLoding {
    margin-left: 35%;
}

.Fiduciaries-add {
    background-color: #720c20;
    /* float: right; */
    font-size: 12px;

}

th.ant-table-cell.manage {
    color: #720c20;
    font-size: medium;
    text-decoration: solid;
    text-align: center;
}

/* .delete_btns{text-align: center; float: left;} */
.ant-input-search-button {
    background: #ccc;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgba(0, 0, 0, 0.45);
    background: lightgray;
}

.ant-table-tbody .table-body {
    padding: 0 !important;
}

.ant-table-tbody>tr>td {
    padding: 0 10px !important;
}


.Delete-fiduciaries {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-right: 5px;
}

.colorChange {
    color: green;
}

.colorRed {
    color: orange;
}



.changeFont {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.buttonColor {
    color: #720c20;
    font-weight: 600;
    font-size: 18px;
}

.borderOnClick {
    border: 2px solid #720c20;
    color: #720c20;
}

.MainDataTable {
    height: 59vh;
    max-height: 59vh;
    overflow: scroll;
}

@media(max-width:600px){
    .contact-Info-Row{
        /* border: 5px solid red !important; */
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}

@media(max-width:425px) {
    .chekboxInactive {
        margin-left: 10px;
        background-color: #18ff25;
    }
    .Agent-Redio-dis {
       display:block !important;
       
     }
}