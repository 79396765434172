.mainCard {
    max-width: 265px !important;
    width: 265px !important;
    margin-right: 10px;
    margin-bottom: 8px;
    height: 290px !important;
    border: 2px solid #720c20 !important;
}

.ProfessionalTeamRow {
    padding-left: 10px;
    padding-right: 10px;
}

.carddChild2 {
    width: 276px;
    padding-top: 20px;
}

.webTagPhoneH5 h5 {
    color: '#444444';
    margin-top: 3px;
    font-size: 15px;
}

.bottomLine {
    padding: 0px !important;
}
.editbutton{
    width: 100%;
    display: flex;
    justify-content: end;
    margin:5px;
    gap: 6px;
    cursor: pointer;
}
.editbutton img{
    width: 20px;
    height: auto;
}

@media(max-width:600px) {
    .ProfessionalTeamRow {
        /* border: 1px solid red; */
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}