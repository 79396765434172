.NewDashboardProfile {
    min-height: 100%;
    background-color: #f7f7f7;
    border-bottom-right-radius: 100%;
}
.site-layout-background {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* .main1{ width: 100%;} */
.NewDashboardfile {

    min-height: 100%;
    background-color: rgba(241, 231, 233, 255);
    border-bottom-left-radius: 100%;
}

.NewDashboardAgents {
    min-height: 100%;
    background-color: rgba(241, 231, 233, 255);
    border-top-right-radius: 100%;
}

.NewDashboardTeam {
    width: 40%;
    height: 100%;
    background-color: #f7f7f7;
    border-top-left-radius: 100%;
}

.New-main {
    position: relative;}
    .section1 {
        text-align: center;
      
        position: absolute;
        left: 0;
        right: 0;
        
      
    }

    .row.ssss {
        margin-top: -163px;
    }
    
 .section1 img{     width: 300px;
    height: auto;
  

}


.PointerImage img {
    position: absolute;
    

    width: 100%;
    margin: 0 auto;
    max-width: 300px;

   
    
}


.pointertxt_ {
    position: absolute;
    z-index: 999;
    margin-top: 228px;
    margin-left: 521px;
}
.New-main.bg-white .row {
    position: relative;
}
/* .pointertxt {
    position: absolute;
    margin-left: 40px;
    margin-top: -247px;
    z-index: 999;
     color: brown;
      font-size: 28px;
       font-family:serif;
} */
.pointertxt {
    position: absolute;
    /* z-index: 999; */
    color: brown;
    font-size: 28px;
    font-family: serif;
    left: 0;
    right: 106px;
    top: 57px
    }
.NewDashboardFamily {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
}

.NewDashboardFamily .row.mt-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.newMain {
   
    height: 100px;
    background-color: white;
}

.newMain img {

     width: 40px;
      height: auto;
      margin-top: 6px;
       padding-bottom: 6px;

}

.Dashboard-agent img {
    width: 150px;
    height: auto;
    /* margin-right: 30px; */
}

.DashboradFiles img {
    width: 80px;
}

.NewDashboardHead {
    color: brown;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size:20px ;
    margin-left: 0;
}

.Health {
    width: 55%;
    height: auto;
    background-color: rgb(249, 254, 254);
    float: right;
}

.Health img {
    width: 15px;
    height: auto;
    padding: 0;
}

.Housing {
    width: 76%;
    height: auto;
    background-color: rgb(249, 254, 254);
    float: right;
}

.Housing img {
    width: 15px;
    height: auto;
    padding: 0;
}

.Legal {
    width: 90%;
    height: 60px;
    /* overflow: auto; */
    overflow-x:scroll;
    /* overflow-y: hidden; */
    display: flex;
    background-color: rgb(249, 254, 254);
    float: right;
}
.text-dark.text-center {
    position: relative;
    z-index: 1;
}

.Legal img {
    width: 20px;
    margin-top: 0;
    height: 20px;
}

.Finance {
    width: 97%;
    height: auto;
    background-color: rgb(249, 254, 254);
    float: right;
}

.Finance img {
    width: 15px;
    height: auto;
}

.Other {
    width: 100%;
    height: auto;
    background-color: rgb(249, 254, 254);
    float: right;
}

.Other img {
    width: 12px;
    height: auto;
}
.healthProfile img { width:30px; height: auto;
 float: left;
 }

    .newMain {
        /* width: 210px; */
        /* height: 100px; */
        background-color: white;
        padding: 0 4px;
        margin-bottom: 20px;
         color: black;
    }
    .newMain a p {
        color: #000;
    }
   .PointerImaeg {
        position: relative;
    }
    .dashboradTeam{ font-size: 15px;}

    /* .main{
        height: 45vh;
    } */
    @media (max-width: 2660px){
        .main1{
         height: 46vh !important;
         margin-right: calc(-.0 * var(--bs-gutter-x));
         
        }
        .main2{
            height: 46vh !important;
            margin-right: calc(-.0 * var(--bs-gutter-x));
        }
      .NewDashboardAgents{
        min-height: 426px;
      }
      .NewDashboardTeam{
        min-height: 428px;
      }
      .section1 img{
        width: 380px;
        /* margin-top: 20px; */
    }
    .Health{
        width: 49%;
        margin-top: 20px;
    }
    .Housing{
        margin-top: 20px;
        width: 59%;
    }
    .Legal{
        width: 69%;
        margin-top: 20px;
    }
    .Finance{
        width: 79%;
        margin-top: 20px;
    }
    .Other{
        width: 90%;
        margin-top: 20px;
    }
     }
     @media (max-width: 1400px) {
        .main1{
            margin-right: calc(-.0 * var(--bs-gutter-x));
        }
        .main2{
            margin-right: calc(-.0 * var(--bs-gutter-x));
        }
       
        

        .section1{
            top: 20px;
        }
        .section1 img{
            width: 300px;
            /* margin-bottom: 0px; */
        }
        .pointertxt {
         font-size: 25px;
         left: 10px;
            }
              
                 
     }
     
    @media only screen and (max-width: 768px) {
        .section1 img {
            width: 250px;
            height: auto;
            /* margin-top: 28px; */
        }

        .setupMAinRow{min-height: 50vh;}
        .Dashboard-agent img {
            width: 100px;
            height: auto;
            margin-right: -21px;
        }
        .NewDashboardHead {
            color: brown;
            font-family: Georgia, 'Times New Roman', Times, serif;
            font-size:14px ;
            margin-left: 0;
             margin-top: 15px;
            
        }
        
        .newMain {
           
            background-color: white;
            padding: 0 4px;
            margin-bottom: 20px;
             font-size: 13px;
              color: black;
        }
        .newMain a p {
            color: #000;
            font-size: 12px;
            text-align: center;
            margin-right: 15px;
            
        }
        
            .DashboradFiles {
                font-size: 12px;
                display: block;
                text-align: center;
            }
            .healthProfile img { width:28px; height: auto;
                float: left; padding-bottom: 5px;}
                .dashboradTeam{ font-size: 15px;}
                .pointertxt {
                    position: absolute;
                    
                    color: brown;
                    font-size: 22px;
                    font-family: serif;
                    left: 0;
                    right: 90px;
                    top: 45px
                    }
                    .newMain img {
                      
                         width: 40px;
                          height: auto;
                          margin-top: 6px;
                           padding-bottom: 6px;
                            margin-right: 18px;
                    
                    }
                    .healthProfile img { width:25px; height: auto;
                        float: left;
                        margin-left: 20px;
                        margin-top: 6px;
                    }
                        .Health{
                            width: 56%;
                        }
                        .Housing{
                            width: 71%;
                        }
                        .Legal{
                            width: 79%;
                        }
                        .Finance{
                            width: 87%;
                        }
                        .Other{
                            width: 97%;
                        }
                        .NewDashboardAgents{
                            min-height: 430px;
                          }
                          .NewDashboardTeam{
                            min-height: 428px;
                            min-width: 429px
                          }
                          
                        }
                        @media (min-width:768px) {
                            .justify-content-around .text-center{
                                margin-bottom: 10px;
                            }
                            
                        }

                        @media (max-width:1024px) {
                            .justify-content-around .text-center{
                                margin-bottom: 10px;
                            }
                          
                            
                        }
/* .main{
    border: 2px solid black;
} */

.container-wrapper .row.main1,
.container-wrapper .row.main2 {
    margin: 0 !important;
}