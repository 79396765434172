.header {
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.header .menu {
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-right: 1rem;

}

.header .logo {
  width: 90%;
  max-width: 17rem;
}

