/* .ant-modal-body{
    padding: 5px !important;
} */

/* .padding{
    z-index: 999999; 
    flex-direction: column; 
} */
.BackToDash{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 9;
   background-color: #ffffff;
}
.hold{
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;
}
.Box{
    display: flex !important;
    justify-content: center !important;
}