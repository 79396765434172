.Info {
  border: 1.5px dashed grey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;
}

.suffixed-classs{
  margin-top: 10px !important;
  margin-right: 15px !important;
}

.main {
  width: 100%;
  height: auto;
}


.ant-form-item {
  margin-bottom: 0px !important;
}
.Input {
  width: 99%;
  margin-top: 10px;
  color: gray;
}
.Input::placeholder {
  color: gray;
}
.InputFull {
  width: 99.5%;
}
.InputFull::placeholder {
  color: gray;
}

.btns {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.uploadImg {
  display: flex;
  justify-content: space-around;
}

.inputfield {
  margin-top: 10px;
}

.input {
  margin-top: 10px;
}

.ant-select {
  margin-top: 10px;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #720c20;
  border-color: #720c20;
  font-weight: bolder;
}
.modalpaddingremoveupper .ant-modal-body {
  padding-top: 0px !important;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
}
.delete-button {
  border: none !important;
  background-color: #720c20;
  color: white;
  /* height: 40px; */
  border-radius: 5px;
  font-weight: 500;
}
.delete-button:hover {
  background-color: #720c20;
  color: white;
  border: none !important;
}
.checkbox-wrapper {
  font-weight: bold;
  display: "flex";
  justify-content: "flex-end";
  margin-top: 80px;
  color: #720c20;
}

.checkbox-col {
  display: flex;
  justify-content: flex-end;
}

.gap {
  width: "100%";
  display: "flex";
  justify-content: "start";
  gap: "20px";
  grid-gap: 1px;
}

.marginLeft {
  margin-left: 20px;
}

.codeSelect {
  width: 100%;
}

.ant-select.ant-select-in-form-item {
  width: 99%;
}

.card {
  justify-content: center;
  /* width: 98%; */
  border: 1.5px solid lightgray;
  border-radius: 10px;
  color: black;
}
.card-div-main {
  max-width: 32%;
  min-width: 32%;
}

.my-modal-class .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  color: #720c20;
}
.ant-card-head {
  padding: 5px 10px;
}
.ant-card-body {
  padding: 5px 15px;
}
@media screen and (max-width: 1100px) {
  #root {
    width: 100%;
  }

  .main {
    width: 100%;
  }

  .Input {
    width: 100%;
    color: grey;
  }
  .InputFull {
    width: 100%;
  }
  .gap {
    width: "100%";

    justify-content: "start";

    grid-gap: 5px;
  }

  .marginLeft {
    margin-left: 0px;
  }

  .codeSelect {
    width: 100%;
  }
  .ant-select.ant-select-in-form-item {
    width: 100%;
  }
  .card-div-main {
    /* border: 10px solid red !important; */
    max-width: 48% !important;
    min-width: 48% !important;
  }
}

@media (max-width: 800px) {
  .card-div-main {
    /* border: 10px solid red !important; */
    max-width: 48% !important;
    min-width: 48% !important;
  }
}

@media screen and (max-width: 600px) {
  #root {
    width: 100%;
  }

  .checkbox-wrapper {
    width: 100%;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    justify-content: end;
    margin-top: 80px;
    color: "#720C20";
  }

  .checkbox-col {
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 0px;
    padding-bottom: 10px;
  }
  .card-div-main {
    /* border: 1px solid red !important; */
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .suffixed-classs{
    margin-top: 0px !important;
    margin-right: 15px !important;
  }
}

@media screen and (max-width: 480px) {
  .input {
    width: 200px;
  }

  .inputfield {
    width: 100%;
  }

  .personalInfo {
    border: 2px dashed grey;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
  }

  .main {
    width: 100%;
  }

  .checkbox-wrapper {
    font-size: 12px;
  }
}
